import clientService from '@/services/queries/clientQueries'
import IntroModal from './IntroModal.vue'
import ClientCard from './ClientCard/ClientCard.vue'

const clientsGuideVersion = '3'

export default {
  components: {
    IntroModal,
    ClientCard
  },

  data() {
    return {
      clients: [],

      showIntroModal: false
    }
  },

  metaInfo() {
    return {
      title: this.$t('agent.clients.list.pageTitle')
    }
  },

  created() {
    this.loadClients()

    let clientsGuide = localStorage.getItem('clientsGuide') ?
        JSON.parse(localStorage.getItem('clientsGuide')) : false

    if (!clientsGuide || !clientsGuide.shown ||
        clientsGuide.version !== clientsGuideVersion) {
      this.showIntroModal = true
      localStorage.setItem('clientsGuide', JSON.stringify({
        version: clientsGuideVersion,
        shown: true
      }))
    }
  },

  methods: {
    loadClients() {
      let params = {
        sorting: 'time_created{desc}'
      }

      clientService.get(params).then(data => {
        this.clients = data
      })
    },

    goToNewClient() {
      this.$router.push({ name: 'agent.clients.new' })
    }
  }
}