<template>
  <base-modal :show="show" @close="$emit('close')" width="500px">
    <div class="heading-accent">{{ $t('agent.clients.introModal.title') }}</div>
    <div style="text-align:justify">
      <p>{{ $t('agent.clients.introModal.guide') }}</p>
    </div>
    <base-button @click="$emit('close')">
      {{ $t('agent.clients.introModal.close') }}
    </base-button>
  </base-modal>
</template>

<script>
  export default {
    props: {
      show: {
        required: true,
        default: false
      }
    }
  }
</script>