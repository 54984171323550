import { render, staticRenderFns } from "./Clients.html?vue&type=template&id=2b970db2&scoped=true&"
import script from "./Clients.js?vue&type=script&lang=js&"
export * from "./Clients.js?vue&type=script&lang=js&"
import style0 from "./Clients.scss?vue&type=style&index=0&id=2b970db2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b970db2",
  null
  
)

export default component.exports