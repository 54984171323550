import clientService from '@/services/queries/clientQueries'
import profileService from '@/services/queries/profileQueries'

export default {
  props: {
    client: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      lastInviteTime: null,

      isInviteInProgress: false,

      showBaseModalConfirm: false,
      isRemovalInProgress: false,
      removalServerError: false,
    }
  },

  mounted() {
    if (!this.client.transient) {
      return
    }

    this.setClientLastInviteTime()
    this.initClientIsInvitedPopup()
  },

  methods: {
    removeClient() {
      this.isRemovalInProgress = true
      this.removalServerError = false

      clientService.remove(this.client.id).then(() => {
        this.$emit('update')

        this.isRemovalInProgress = false
        this.showBaseModalConfirm = false
      }, () => {
        this.removalServerError = true
        this.isRemovalInProgress = false
      })
    },

    inviteClient(clientId) {
      this.isInviteInProgress = true

      clientService.invite(clientId, "transient_client").then(() => {
        this.$refs.invite.$el._tippy.show()
        this.setClientLastInviteTime()

        this.isInviteInProgress = false
      }).catch(() => {
        this.isInviteInProgress = false
      })
    },

    setClientLastInviteTime() {
      profileService.getInvites().then(invites => {
        let clientInvites = invites.filter(invite => invite.client_id === this.client.id)
        let lastInvite = clientInvites.length ? clientInvites[clientInvites.length - 1] : null

        if (!lastInvite) {
          return
        }

        this.lastInviteTime = lastInvite.time_created
      })
    },

    initClientIsInvitedPopup() {
      tippy(this.$refs.invite.$el, {
        content: this.$refs.inviteIsSent,
        distance: 20,
        animation: 'shift-away',
        placement: 'bottom',
        arrow: true,
        trigger: 'manual',
        onShown(tooltip) {
          setTimeout(() => {
            tooltip.hide()
          }, 800)
        },
      })
    }
  }
}
